import "./location-uruguay.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "uruguay" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
 locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationUruguayPage = ({ data }) => {
    const pageTitle = "Careers - Uruguay"
    const pageDescription = "Get to Know Our Uruguay Offices"
    const lang = 'EN'
    const meta = []

    const montevideo = {
        name: "Montevideo",
        text: "With a growing $1.2 billion IT industry and more than 700 tech companies exporting software across 52 different markets, Uruguay is the leading software exporter per capita in South America, and third worldwide, primarily focused in its vibrant capital, Montevideo.",
        secondText: "Our Montevideo office is a fully renovated two-story building, with a big yard, barbecue, and a lot of space to relax and get inspired. It is located in the heart of Pocitos, a quiet and historically residential neighborhood."
    }

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Uruguay", url: "#" }
    ]

    const location = {
        title: "Uruguay",
        cities: ["Montevideo"],
        sectionClassName: "section-location-uruguay",
        modalClassName: "modal-location-uruguay"
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={montevideo.name}>
                        <span className="city-title">{montevideo.name}</span>
                        <p className="city-text">
                            {montevideo.text}
                        </p>
                        <p>
                            {montevideo.secondText}
                        </p>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Uruguay"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationUruguayPage
